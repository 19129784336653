import { Pipe, PipeTransform } from '@angular/core';
import { IDictionaryValue } from '@cinetixx/cinetixx-ui';

@Pipe({
  name: 'dictionaryId'
})
export class DictionaryIdPipe implements PipeTransform {

  public transform(name: string, dictionary: IDictionaryValue[]): number {
    return dictionary.find(dct => dct.name.toLowerCase() === name.toLowerCase()).id;
  }
}

